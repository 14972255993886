import { useMemo } from 'react';
import { SearchState, SEARCH_RESULTS_AMOUNT } from 'shared';

import { DocLabFilterQueryParams } from '../components/pages/DocLabCollectionPage';
import { DocLabFilterEnum, useSearchDocLabFiltersQuery, useSearchDocLabHitsQuery } from '../gql/api';

const useDocLabSearch = (searchState: SearchState<typeof DocLabFilterQueryParams>) => {
    const { debouncedQuery, queryFilters, page } = searchState;
    const offset = (page - 1) * SEARCH_RESULTS_AMOUNT;
    const hitsResult = useSearchDocLabHitsQuery(
        {
            query: debouncedQuery,
            filters: (Object.entries(queryFilters) as [DocLabFilterEnum, string[]][])
                .filter(([, value]) => value.length > 0) // remove empty filters
                .map(([key, value]) => ({
                    key,
                    value,
                })),
            offset,
            limit: SEARCH_RESULTS_AMOUNT,
        },
        { keepPreviousData: true }
    );
    const filtersResult = useSearchDocLabFiltersQuery(
        {
            filters: [],
        },
        { keepPreviousData: true }
    );

    const totalPages = useMemo(
        () => Math.ceil((hitsResult.data?.searchDocLab?.totalHits ?? 0) / SEARCH_RESULTS_AMOUNT),
        [hitsResult.data?.searchDocLab?.totalHits]
    );

    const totalHits = useMemo(
        () => hitsResult.data?.searchDocLab?.totalHits,
        [hitsResult.data?.searchDocLab?.totalHits]
    );

    const filters = useMemo(
        () => filtersResult.data?.searchDocLab?.filters,
        [filtersResult.data?.searchDocLab?.filters]
    );

    return {
        totalPages,
        totalHits,
        filters,
        offset,
        hits: hitsResult.data?.searchDocLab?.hits,
        isError: hitsResult.isError || filtersResult.isError,
        isFetching: hitsResult.isFetching || filtersResult.isFetching,
    };
};

export default useDocLabSearch;
