import { DocLabFilterEnum } from '../gql/api';
import { defineMessages } from 'react-intl';

const docLabFilterMessages = defineMessages<DocLabFilterEnum>({
    [DocLabFilterEnum.CountriesOfProduction]: { defaultMessage: 'Country of Production' },
    [DocLabFilterEnum.Format]: { defaultMessage: 'Format' },
    [DocLabFilterEnum.NewMediaType]: { defaultMessage: 'Type' },
    [DocLabFilterEnum.Section]: { defaultMessage: 'Section' },
    [DocLabFilterEnum.Tags]: { defaultMessage: 'Subject' },
});

export default docLabFilterMessages;
