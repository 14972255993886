import { useMemo } from 'react';
import { SearchState, SEARCH_RESULTS_AMOUNT } from 'shared';

import { ProjectSpaceFilterQueryParams } from '../components/pages/ProjectSpaceCollectionPage';
import { ProjectSpaceFilterEnum, useSearchProjectSpaceFiltersQuery, useSearchProjectSpaceHitsQuery } from '../gql/api';

const useProjectSpaceSearch = (searchState: SearchState<typeof ProjectSpaceFilterQueryParams>) => {
    const { debouncedQuery, queryFilters, page } = searchState;
    const offset = (page - 1) * SEARCH_RESULTS_AMOUNT;
    const hitsResult = useSearchProjectSpaceHitsQuery(
        {
            query: debouncedQuery,
            filters: (Object.entries(queryFilters) as [ProjectSpaceFilterEnum, string[]][])
                .filter(([, value]) => value.length > 0) // remove empty filters
                .map(([key, value]) => ({
                    key,
                    value,
                })),
            offset,
            limit: SEARCH_RESULTS_AMOUNT,
        },
        { keepPreviousData: true }
    );
    const filtersResult = useSearchProjectSpaceFiltersQuery(
        {
            filters: [],
        },
        { keepPreviousData: true }
    );

    const totalPages = useMemo(
        () => Math.ceil((hitsResult.data?.searchProjectSpace?.totalHits ?? 0) / SEARCH_RESULTS_AMOUNT),
        [hitsResult.data?.searchProjectSpace?.totalHits]
    );

    const totalHits = useMemo(
        () => hitsResult.data?.searchProjectSpace?.totalHits,
        [hitsResult.data?.searchProjectSpace?.totalHits]
    );

    const filters = useMemo(
        () => filtersResult.data?.searchProjectSpace?.filters,
        [filtersResult.data?.searchProjectSpace?.filters]
    );

    return {
        totalPages,
        totalHits,
        filters,
        offset,
        hits: hitsResult.data?.searchProjectSpace?.hits,
        isError: hitsResult.isError || filtersResult.isError,
        isFetching: hitsResult.isFetching || filtersResult.isFetching,
    };
};

export default useProjectSpaceSearch;
