import { ContentPage } from '../../pages/[...uri]';
import React, { ComponentProps, FC, useMemo } from 'react';
import {
    DocLabFilterEnum,
    SearchDocLabFiltersQueryVariables,
    SearchDocLabHitsQueryVariables,
    useSearchDocLabFiltersQuery,
    useSearchDocLabHitsQuery,
} from '../../gql/api';
import { ArrayParam, QueryParamConfig, withDefault } from 'use-query-params';
import useDocLabSearch from '../../hooks/useDocLabSearch';
import { GetStaticProps } from 'next';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import {
    cmsImageToOpenGraph,
    CollectionOverviewTemplate,
    fetchApiData,
    getMetaForFilmOrProjectHit,
    SEARCH_RESULTS_AMOUNT,
    useSearchState,
} from 'shared';
import loadIntlMessages from '../../utils/loadIntlMessages';
import docLabFilterMessages from '../../constants/docLabFilterMessages';
import ProfessionalsBreadcrumbs from '../ProfessionalsBreadcrumbs';

type DocLabCollectionContentPage = Extract<
    ContentPage,
    { __typename: 'professionalsContentPages_doclabCollectionPage_Entry' }
>;

export const DocLabFilterQueryParams: Record<DocLabFilterEnum, QueryParamConfig<string[]>> = Object.assign(
    {},
    ...Object.values(DocLabFilterEnum).map(value => ({ [value]: withDefault(ArrayParam, []) }))
);

const DocLabCollectionPage: FC<{ data: DocLabCollectionContentPage }> = ({ data }) => {
    const searchState = useSearchState(DocLabFilterQueryParams);
    const { hits, ...docLabSearch } = useDocLabSearch(searchState);
    const processedHits: ComponentProps<typeof CollectionOverviewTemplate>['hits'] = useMemo(
        () =>
            (hits || []).map(hit => ({
                id: hit.id,
                title: hit.fullPreferredTitle,
                image: hit.publications.favoriteImage,
                description: hit.intro?.translation,
                meta: getMetaForFilmOrProjectHit({ filmOrProject: hit, filmOrProjectType: 'docLab' }),
            })),
        [hits]
    );

    return (
        <CollectionOverviewTemplate
            collectionPath="doclab"
            breadcrumbs={<ProfessionalsBreadcrumbs />}
            pageTitle={data.title}
            pageIntroText={data.introText}
            pageOpenGraphImages={cmsImageToOpenGraph(data.navThumbnail)}
            queryParams={DocLabFilterQueryParams}
            filterMessages={docLabFilterMessages}
            hits={processedHits}
            {...docLabSearch}
        />
    );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    const queryClient = new QueryClient();
    const hitVars: SearchDocLabHitsQueryVariables = {
        query: '',
        filters: [],
        offset: 0,
        limit: SEARCH_RESULTS_AMOUNT,
    };
    await queryClient.prefetchQuery(useSearchDocLabHitsQuery.getKey(hitVars), () =>
        fetchApiData({
            locale,
            query: useSearchDocLabHitsQuery.document,
            variables: hitVars,
        })
    );
    const filterVars: SearchDocLabFiltersQueryVariables = { filters: [] };
    await queryClient.prefetchQuery(useSearchDocLabFiltersQuery.getKey(filterVars), () =>
        fetchApiData({
            locale,
            query: useSearchDocLabFiltersQuery.document,
            variables: filterVars,
        })
    );

    return {
        props: {
            dehydratedState: dehydrate(queryClient),
            intlMessages: await loadIntlMessages(locale),
        },
    };
};

export default DocLabCollectionPage;
