import { useMemo } from 'react';
import { SearchState, SEARCH_RESULTS_AMOUNT } from 'shared';

import { ForumFilterQueryParams } from '../components/pages/ForumCollectionPage';
import { ForumFilterEnum, useSearchForumFiltersQuery, useSearchForumHitsQuery } from '../gql/api';

const useForumSearch = (searchState: SearchState<typeof ForumFilterQueryParams>) => {
    const { debouncedQuery, queryFilters, page } = searchState;
    const offset = (page - 1) * SEARCH_RESULTS_AMOUNT;
    const hitsResult = useSearchForumHitsQuery(
        {
            query: debouncedQuery,
            filters: (Object.entries(queryFilters) as [ForumFilterEnum, string[]][])
                .filter(([, value]) => value.length > 0) // remove empty filters
                .map(([key, value]) => ({
                    key,
                    value,
                })),
            offset,
            limit: SEARCH_RESULTS_AMOUNT,
        },
        { keepPreviousData: true }
    );
    const filtersResult = useSearchForumFiltersQuery(
        {
            filters: [],
        },
        { keepPreviousData: true }
    );

    const totalPages = useMemo(
        () => Math.ceil((hitsResult.data?.searchForum?.totalHits ?? 0) / SEARCH_RESULTS_AMOUNT),
        [hitsResult.data?.searchForum?.totalHits]
    );

    const totalHits = useMemo(() => hitsResult.data?.searchForum?.totalHits, [hitsResult.data?.searchForum?.totalHits]);

    const filters = useMemo(() => filtersResult.data?.searchForum?.filters, [filtersResult.data?.searchForum?.filters]);

    return {
        totalPages,
        totalHits,
        filters,
        offset,
        hits: hitsResult.data?.searchForum?.hits,
        isError: hitsResult.isError || filtersResult.isError,
        isFetching: hitsResult.isFetching || filtersResult.isFetching,
    };
};

export default useForumSearch;
