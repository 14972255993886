import { ProjectSpaceFilterEnum } from '../gql/api';
import { defineMessages } from 'react-intl';

const projectSpaceFilterMessages = defineMessages<ProjectSpaceFilterEnum>({
    [ProjectSpaceFilterEnum.ProjectSpaceRequestType]: { defaultMessage: 'Program' },
    [ProjectSpaceFilterEnum.CountriesOfProduction]: { defaultMessage: 'Country of Production' },
    [ProjectSpaceFilterEnum.EditionYear]: { defaultMessage: 'Year of Selection' },
    [ProjectSpaceFilterEnum.ProjectSpaceProductionStatus]: { defaultMessage: 'Current Production Status' },
});

export default projectSpaceFilterMessages;
