import { BerthaFundFilterEnum } from '../gql/api';
import { defineMessages } from 'react-intl';

const berthaFundFilterMessages = defineMessages<BerthaFundFilterEnum>({
    [BerthaFundFilterEnum.CountriesOfProduction]: { defaultMessage: 'Country of Production' },
    [BerthaFundFilterEnum.EditionYear]: { defaultMessage: 'Funded in' },
    [BerthaFundFilterEnum.FundCountriesOfCoproduction]: { defaultMessage: 'Country of Coproduction' },
    [BerthaFundFilterEnum.FundProductionStatus]: { defaultMessage: 'Current Production Status' },
    [BerthaFundFilterEnum.FundRequestType]: { defaultMessage: 'Grant' },
});

export default berthaFundFilterMessages;
