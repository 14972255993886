import { ContentPage } from '../../pages/[...uri]';
import React, { ComponentProps, FC, useMemo } from 'react';
import {
    ProjectSpaceFilterEnum,
    SearchProjectSpaceFiltersQueryVariables,
    SearchProjectSpaceHitsQueryVariables,
    useSearchProjectSpaceFiltersQuery,
    useSearchProjectSpaceHitsQuery,
} from '../../gql/api';
import { ArrayParam, QueryParamConfig, withDefault } from 'use-query-params';
import useProjectSpaceSearch from '../../hooks/useProjectSpaceSearch';
import { GetStaticProps } from 'next';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import {
    cmsImageToOpenGraph,
    CollectionOverviewTemplate,
    fetchApiData,
    getMetaForFilmOrProjectHit,
    SEARCH_RESULTS_AMOUNT,
    useSearchState,
} from 'shared';
import loadIntlMessages from '../../utils/loadIntlMessages';
import projectSpaceFilterMessages from '../../constants/projectSpaceFilterMessages';
import ProfessionalsBreadcrumbs from '../ProfessionalsBreadcrumbs';

type ProjectSpaceCollectionContentPage = Extract<
    ContentPage,
    { __typename: 'professionalsContentPages_projectSpaceCollectionPage_Entry' }
>;

export const ProjectSpaceFilterQueryParams: Record<ProjectSpaceFilterEnum, QueryParamConfig<string[]>> = Object.assign(
    {},
    ...Object.values(ProjectSpaceFilterEnum).map(value => ({ [value]: withDefault(ArrayParam, []) }))
);

const ProjectSpaceCollectionPage: FC<{ data: ProjectSpaceCollectionContentPage }> = ({ data }) => {
    const searchState = useSearchState(ProjectSpaceFilterQueryParams);
    const { hits, ...projectSpaceSearch } = useProjectSpaceSearch(searchState);
    const processedHits: ComponentProps<typeof CollectionOverviewTemplate>['hits'] = useMemo(
        () =>
            (hits || []).map(hit => ({
                id: hit.id,
                title: hit.fullPreferredTitle,
                image: hit.publications.favoriteImage,
                description: hit.logline,
                meta: getMetaForFilmOrProjectHit({ filmOrProject: hit, filmOrProjectType: 'projectSpace' }),
            })),
        [hits]
    );

    return (
        <CollectionOverviewTemplate
            collectionPath="project-space"
            breadcrumbs={<ProfessionalsBreadcrumbs />}
            pageTitle={data.title}
            pageIntroText={data.introText}
            pageOpenGraphImages={cmsImageToOpenGraph(data.navThumbnail)}
            queryParams={ProjectSpaceFilterQueryParams}
            filterMessages={projectSpaceFilterMessages}
            hits={processedHits}
            {...projectSpaceSearch}
        />
    );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    const queryClient = new QueryClient();
    const hitVars: SearchProjectSpaceHitsQueryVariables = {
        query: '',
        filters: [],
        offset: 0,
        limit: SEARCH_RESULTS_AMOUNT,
    };
    await queryClient.prefetchQuery(useSearchProjectSpaceHitsQuery.getKey(hitVars), () =>
        fetchApiData({
            locale,
            query: useSearchProjectSpaceHitsQuery.document,
            variables: hitVars,
        })
    );
    const filterVars: SearchProjectSpaceFiltersQueryVariables = { filters: [] };
    await queryClient.prefetchQuery(useSearchProjectSpaceFiltersQuery.getKey(filterVars), () =>
        fetchApiData({
            locale,
            query: useSearchProjectSpaceFiltersQuery.document,
            variables: filterVars,
        })
    );

    return {
        props: {
            dehydratedState: dehydrate(queryClient),
            intlMessages: await loadIntlMessages(locale),
        },
    };
};

export default ProjectSpaceCollectionPage;
