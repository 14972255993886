import { GetStaticProps } from 'next';
import React, { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ArticlesOverviewTemplate, cmsImageToOpenGraph, fetchCraftData, getMetaForStory, PreviewData } from 'shared';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';

import { dehydrate, QueryClient } from '@tanstack/react-query';

import { StoriesOverviewQuery, StoriesOverviewQueryVariables, useStoriesOverviewQuery } from '../../gql/cms';
import { ContentPage } from '../../pages/[...uri]';
import loadIntlMessages from '../../utils/loadIntlMessages';
import siteHandleByLocale from '../../utils/siteHandleByLocale';
import ProfessionalsBreadcrumbs from '../ProfessionalsBreadcrumbs';

type StoryArticleOverviewPage = Extract<ContentPage, { __typename: 'professionalsContentPages_storiesOverview_Entry' }>;

const ITEMS_PER_PAGE = 10;

const StoriesOverview: FC<{ data: StoryArticleOverviewPage }> = ({ data: contentPageData }) => {
    const [page] = useQueryParam('page', withDefault(NumberParam, 1));
    const intl = useIntl();
    const { locale } = intl;
    const { data: overviewData, isFetching } = useStoriesOverviewQuery({
        site: siteHandleByLocale[locale],
        limit: ITEMS_PER_PAGE,
        offset: (page - 1) * ITEMS_PER_PAGE,
    });
    const stories = overviewData?.professionalsStoriesEntries;
    const storiesWithMeta = useMemo(
        () =>
            stories?.map(story => ({
                ...story,
                meta: getMetaForStory(story, intl),
                href: `/stories/${story.slug}`,
            })),
        [stories, intl]
    );
    const featuredStoriesWithMeta: React.ComponentProps<typeof ArticlesOverviewTemplate>['featuredArticles'] = useMemo(
        () =>
            contentPageData.featuredStories.map(featuredStory => {
                if (featuredStory.story[0].__typename !== 'professionalsStories_story_Entry') {
                    throw Error('Non story passed to featured story');
                }
                const story = featuredStory.story[0];
                return {
                    ...story,
                    meta: getMetaForStory(story, intl),
                    href: `/stories/${story.slug}`,
                };
            }),
        [contentPageData.featuredStories, intl]
    );

    return (
        <ArticlesOverviewTemplate
            pageTitle={contentPageData.title}
            pageIntro={contentPageData.introText}
            pageOpenGraphImages={cmsImageToOpenGraph(contentPageData.navThumbnail)}
            loading={isFetching}
            featuredArticles={featuredStoriesWithMeta}
            articles={storiesWithMeta}
            articleCount={overviewData?.articleCount}
            breadcrumbs={<ProfessionalsBreadcrumbs />}
        />
    );
};

export const getStaticProps: GetStaticProps = async ctx => {
    const { locale, previewData } = ctx;
    const queryClient = new QueryClient();
    const vars = {
        site: siteHandleByLocale[locale],
        limit: ITEMS_PER_PAGE,
        offset: 0,
    };

    await queryClient.prefetchQuery(useStoriesOverviewQuery.getKey(vars), () =>
        fetchCraftData<StoriesOverviewQuery, StoriesOverviewQueryVariables>({
            query: useStoriesOverviewQuery.document,
            variables: vars,
            previewData: previewData as PreviewData,
        })
    );

    return {
        props: {
            dehydratedState: dehydrate(queryClient),
            intlMessages: await loadIntlMessages(locale),
        },
    };
};

export default StoriesOverview;
