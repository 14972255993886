import { ContentPage } from '../../pages/[...uri]';
import React, { ComponentProps, FC, useMemo } from 'react';
import {
    ForumFilterEnum,
    SearchForumFiltersQueryVariables,
    SearchForumHitsQueryVariables,
    useMeetingRequestLinkQuery,
    useSearchForumFiltersQuery,
    useSearchForumHitsQuery,
} from '../../gql/api';
import { ArrayParam, QueryParamConfig, withDefault } from 'use-query-params';
import useForumSearch from '../../hooks/useForumSearch ';
import { GetStaticProps } from 'next';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import {
    cmsImageToOpenGraph,
    CollectionOverviewTemplate,
    fetchApiData,
    getMetaForFilmOrProjectHit,
    isLoggedIn,
    SEARCH_RESULTS_AMOUNT,
    useSearchState,
} from 'shared';
import loadIntlMessages from '../../utils/loadIntlMessages';
import forumFilterMessages from '../../constants/forumFilterMessages';
import ProfessionalsBreadcrumbs from '../ProfessionalsBreadcrumbs';
import { useSession } from 'next-auth/react';

type ForumCollectionContentPage = Extract<
    ContentPage,
    { __typename: 'professionalsContentPages_forumCollectionPage_Entry' }
>;

export const ForumFilterQueryParams: Record<ForumFilterEnum, QueryParamConfig<string[]>> = Object.assign(
    {},
    ...Object.values(ForumFilterEnum).map(value => ({ [value]: withDefault(ArrayParam, []) }))
);

const ForumCollectionPage: FC<{ data: ForumCollectionContentPage }> = ({ data }) => {
    const searchState = useSearchState(ForumFilterQueryParams);
    const { hits, ...forumSearch } = useForumSearch(searchState);
    const session = useSession();
    const { data: userData } = useMeetingRequestLinkQuery(null, { enabled: !!isLoggedIn(session?.status) });
    const meetingRequestLink = userData?.viewer?.meetingRequestLink;
    const processedHits: ComponentProps<typeof CollectionOverviewTemplate>['hits'] = useMemo(
        () =>
            (hits || []).map(hit => ({
                id: hit.id,
                title: hit.fullPreferredTitle,
                image: hit.publications.favoriteImage,
                description: hit.logline,
                meta: getMetaForFilmOrProjectHit({ filmOrProject: hit, filmOrProjectType: 'forum' }),
            })),
        [hits]
    );

    return (
        <CollectionOverviewTemplate
            collectionPath="forum"
            breadcrumbs={<ProfessionalsBreadcrumbs />}
            pageTitle={data.title}
            pageIntroText={data.introText}
            pageOpenGraphImages={cmsImageToOpenGraph(data.navThumbnail)}
            queryParams={ForumFilterQueryParams}
            filterMessages={forumFilterMessages}
            hits={processedHits}
            meetingRequestLink={meetingRequestLink}
            {...forumSearch}
        />
    );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    const queryClient = new QueryClient();
    const hitVars: SearchForumHitsQueryVariables = {
        query: '',
        filters: [],
        offset: 0,
        limit: SEARCH_RESULTS_AMOUNT,
    };
    await queryClient.prefetchQuery(useSearchForumHitsQuery.getKey(hitVars), () =>
        fetchApiData({
            locale,
            query: useSearchForumHitsQuery.document,
            variables: hitVars,
        })
    );
    const filterVars: SearchForumFiltersQueryVariables = { filters: [] };
    await queryClient.prefetchQuery(useSearchForumFiltersQuery.getKey(filterVars), () =>
        fetchApiData({
            locale,
            query: useSearchForumFiltersQuery.document,
            variables: filterVars,
        })
    );

    return {
        props: {
            dehydratedState: dehydrate(queryClient),
            intlMessages: await loadIntlMessages(locale),
        },
    };
};

export default ForumCollectionPage;
