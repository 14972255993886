import { ForumFilterEnum } from '../gql/api';
import { defineMessages } from 'react-intl';

const forumFilterMessages = defineMessages<ForumFilterEnum>({
    [ForumFilterEnum.ForumRequestType]: { defaultMessage: 'Category' },
    [ForumFilterEnum.CountriesOfProduction]: { defaultMessage: 'Country of Production' },
    [ForumFilterEnum.ForumSubject]: { defaultMessage: 'Topic' },
    [ForumFilterEnum.ForumProductionStatus]: { defaultMessage: 'Production Status' },
    [ForumFilterEnum.ForumCountriesOfCoproduction]: { defaultMessage: 'Country of Coproduction' },
});

export default forumFilterMessages;
