import merge from 'deepmerge';
import { GetStaticPaths, GetStaticProps } from 'next';
import Error from 'next/error';
import { FC, useEffect } from 'react';

import Information from '../components/pages/InformationPage';
import Navigation from '../components/pages/NavigationPage';
import StoriesOverview, {
    getStaticProps as getStoriesOverviewStaticProps,
} from '../components/pages/StoriesOverviewPage';

import {
    ContentPagePathsQuery,
    ContentPagePathsQueryVariables,
    ContentPagesQuery,
    ContentPagesQueryVariables,
    useContentPagePathsQuery,
    useContentPagesQuery,
} from '../gql/cms';
import {
    fetchCraftData,
    gtm,
    LoadingContentPageTemplate,
    prefetchContentBlocks,
    PreviewData,
    useParentPage,
} from 'shared';
import loadIntlMessages from '../utils/loadIntlMessages';
import siteHandleByLocale, { getLocaleBySiteHandle } from '../utils/siteHandleByLocale';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import prefetchGlobals from '../utils/prefetchGlobals';
import DocLabCollectionPage, {
    getStaticProps as getDocLabCollectionStaticProps,
} from '../components/pages/DocLabCollectionPage';
import BerthaFundCollectionPage, {
    getStaticProps as getBerthaFundCollectionStaticProps,
} from '../components/pages/BerthaFundCollectionPage';
import DocsForSaleCollectionPage, {
    getStaticProps as getDocsForSaleCollectionStaticProps,
} from '../components/pages/DocsForSaleCollectionPage';
import ForumCollectionPage, {
    getStaticProps as getForumCollectionProps,
} from '../components/pages/ForumCollectionPage';
import ProjectSpaceCollectionPage, {
    getStaticProps as getProjectSpaceCollectionProps,
} from '../components/pages/ProjectSpaceCollectionPage';
import { useRouter } from 'next/router';

export const DEFAULT_REVALIDATION_TIME = 60 * 5;

const CatchAll: FC = () => {
    const { query, locale, isFallback } = useRouter();
    const uri = [...(query?.uri ?? [])].join('/');
    const site = siteHandleByLocale[locale];
    const { data: contentData } = useContentPagesQuery({
        site,
        uri,
        search: getRedirectSearch(uri),
    });
    const entry = contentData?.entry;
    const { parentPagePath } = useParentPage();
    const parentPagePathWithoutParams = parentPagePath.split(/[?#]/)[0];
    const pageUri = Array.isArray(query.uri) ? query.uri.join('/') : query.uri;

    useEffect(() => {
        gtm.pageView(`/${pageUri}`, {
            page_pillar: 'professionals',
            page_navigation: parentPagePathWithoutParams,
            page_language: locale,
        });
    }, [pageUri, parentPagePathWithoutParams, locale]);

    if (isFallback) {
        return <LoadingContentPageTemplate />;
    }

    switch (entry?.__typename) {
        case 'professionalsContentPages_informationPage_Entry':
            return <Information data={entry} />;
        case 'professionalsContentPages_navigationPage_Entry':
            return <Navigation data={entry} />;
        case 'professionalsContentPages_storiesOverview_Entry':
            return <StoriesOverview data={entry} />;
        case 'professionalsContentPages_doclabCollectionPage_Entry':
            return <DocLabCollectionPage data={entry} />;
        case 'professionalsContentPages_berthaFundCollectionPage_Entry':
            return <BerthaFundCollectionPage data={entry} />;
        case 'professionalsContentPages_docsForSaleCollectionPage_Entry':
            return <DocsForSaleCollectionPage data={entry} />;
        case 'professionalsContentPages_forumCollectionPage_Entry':
            return <ForumCollectionPage data={entry} />;
        case 'professionalsContentPages_projectSpaceCollectionPage_Entry':
            return <ProjectSpaceCollectionPage data={entry} />;
        default:
            // this shouldn't happen (should've been handled by getStaticProps already),
            // but still show an error page in case it does.
            return <Error statusCode={404} />;
    }
};

export type ContentPage = ContentPagesQuery['entry'];

export const getStaticPaths: GetStaticPaths = async () => {
    const data = await fetchCraftData<ContentPagePathsQuery, ContentPagePathsQueryVariables>({
        query: useContentPagePathsQuery.document,
    });

    return {
        paths: data.professionalsContentPagesEntries.map(entry => ({
            params: {
                uri: entry.uri.split('/'),
                locale: getLocaleBySiteHandle(entry.siteHandle),
            },
        })),
        fallback: true,
    };
};

export const getRedirectSearch = (uri: string) => `siteHandle:professionals path::${uri}`;

export const getStaticProps: GetStaticProps = async ctx => {
    const { params, locale, previewData } = ctx;
    const queryClient = new QueryClient();

    const uri = [...params.uri].join('/');
    const variables = {
        uri,
        site: siteHandleByLocale[locale],
        search: getRedirectSearch(uri),
    };
    const data = await queryClient.fetchQuery(useContentPagesQuery.getKey(variables), () =>
        fetchCraftData<ContentPagesQuery, ContentPagesQueryVariables>({
            variables,
            query: useContentPagesQuery.document,
            previewData: previewData as PreviewData,
        })
    );

    if (!data.entry) {
        const set = data.globalSets?.[0];
        if (set.__typename === 'redirects_GlobalSet') {
            const [redirect] = set.redirects;
            if (redirect) {
                return {
                    redirect: {
                        statusCode: 307,
                        destination: redirect.redirectUrl,
                    },
                    revalidate: DEFAULT_REVALIDATION_TIME,
                };
            }
        }

        return {
            notFound: true,
            revalidate: DEFAULT_REVALIDATION_TIME,
        };
    }

    if (data.entry.__typename === 'professionalsContentPages_informationPage_Entry') {
        await prefetchContentBlocks(queryClient, data.entry.contentBlocks, locale);
    }
    await prefetchGlobals(queryClient, locale, { pathType: 'CURRENT_URI', uri });

    let result: ReturnType<GetStaticProps> = {
        props: {
            intlMessages: await loadIntlMessages(locale),
            dehydratedState: dehydrate(queryClient),
        },
        revalidate: DEFAULT_REVALIDATION_TIME,
    };

    // some pages require more data than just the data in their CMS entry.
    switch (data.entry.__typename) {
        case 'professionalsContentPages_storiesOverview_Entry':
            result = merge(result, await getStoriesOverviewStaticProps(ctx));
            break;
        case 'professionalsContentPages_berthaFundCollectionPage_Entry':
            result = merge(result, await getBerthaFundCollectionStaticProps(ctx));
            break;
        case 'professionalsContentPages_doclabCollectionPage_Entry':
            result = merge(result, await getDocLabCollectionStaticProps(ctx));
            break;
        case 'professionalsContentPages_docsForSaleCollectionPage_Entry':
            result = merge(result, await getDocsForSaleCollectionStaticProps(ctx));
            break;
        case 'professionalsContentPages_forumCollectionPage_Entry':
            result = merge(result, await getForumCollectionProps(ctx));
            break;
        case 'professionalsContentPages_projectSpaceCollectionPage_Entry':
            result = merge(result, await getProjectSpaceCollectionProps(ctx));
            break;
        default:
    }
    return result;
};

export default CatchAll;
