import { ContentPage } from '../../pages/[...uri]';
import React, { ComponentProps, FC, useMemo } from 'react';
import {
    BerthaFundFilterEnum,
    SearchBerthaFundFiltersQueryVariables,
    SearchBerthaFundHitsQueryVariables,
    useSearchBerthaFundFiltersQuery,
    useSearchBerthaFundHitsQuery,
} from '../../gql/api';
import { ArrayParam, QueryParamConfig, withDefault } from 'use-query-params';
import useBerthaFundSearch from '../../hooks/useBerthaFundSearch';
import { GetStaticProps } from 'next';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import {
    cmsImageToOpenGraph,
    CollectionOverviewTemplate,
    fetchApiData,
    getMetaForFilmOrProjectHit,
    SEARCH_RESULTS_AMOUNT,
    useSearchState,
} from 'shared';
import loadIntlMessages from '../../utils/loadIntlMessages';
import berthaFundFilterMessages from '../../constants/berthaFundFilterMessages';
import ProfessionalsBreadcrumbs from '../ProfessionalsBreadcrumbs';
import orderBy from 'lodash.orderby';

type BerthaFundCollectionContentPage = Extract<
    ContentPage,
    { __typename: 'professionalsContentPages_berthaFundCollectionPage_Entry' }
>;

export const BerthaFundFilterQueryParams: Record<BerthaFundFilterEnum, QueryParamConfig<string[]>> = Object.assign(
    {},
    ...Object.values(BerthaFundFilterEnum).map(value => ({ [value]: withDefault(ArrayParam, []) }))
);

const BerthaFundCollectionPage: FC<{ data: BerthaFundCollectionContentPage }> = ({ data }) => {
    const searchState = useSearchState(BerthaFundFilterQueryParams);
    const { hits, ...berthaFundSearch } = useBerthaFundSearch(searchState);
    const processedHits: ComponentProps<typeof CollectionOverviewTemplate>['hits'] = useMemo(
        () =>
            (hits || []).map(hit => ({
                id: hit.id,
                title: hit.fullPreferredTitle,
                image: hit.publications.favoriteImage,
                description: hit.logline,
                meta: getMetaForFilmOrProjectHit({ filmOrProject: hit, filmOrProjectType: 'berthaFund' }),
            })),
        [hits]
    );

    return (
        <CollectionOverviewTemplate
            collectionPath="bertha-fund"
            breadcrumbs={<ProfessionalsBreadcrumbs />}
            pageTitle={data.title}
            pageIntroText={data.introText}
            pageOpenGraphImages={cmsImageToOpenGraph(data.navThumbnail)}
            queryParams={BerthaFundFilterQueryParams}
            filterMessages={berthaFundFilterMessages}
            hits={processedHits}
            sortFilterItems={(filter, amounts) => {
                switch (filter) {
                    case BerthaFundFilterEnum.EditionYear:
                        return orderBy(amounts, amount => amount.key.toLowerCase(), 'desc');
                    default:
                        return orderBy(amounts, amount => amount.key.toLowerCase());
                }
            }}
            {...berthaFundSearch}
        />
    );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    const queryClient = new QueryClient();
    const hitVars: SearchBerthaFundHitsQueryVariables = {
        query: '',
        filters: [],
        offset: 0,
        limit: SEARCH_RESULTS_AMOUNT,
    };
    await queryClient.prefetchQuery(useSearchBerthaFundHitsQuery.getKey(hitVars), () =>
        fetchApiData({
            locale,
            query: useSearchBerthaFundHitsQuery.document,
            variables: hitVars,
        })
    );
    const filterVars: SearchBerthaFundFiltersQueryVariables = { filters: [] };
    await queryClient.prefetchQuery(useSearchBerthaFundFiltersQuery.getKey(filterVars), () =>
        fetchApiData({
            locale,
            query: useSearchBerthaFundFiltersQuery.document,
            variables: filterVars,
        })
    );

    return {
        props: {
            dehydratedState: dehydrate(queryClient),
            intlMessages: await loadIntlMessages(locale),
        },
    };
};

export default BerthaFundCollectionPage;
