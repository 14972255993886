import { ContentPage } from '../../pages/[...uri]';
import React, { FC, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';
import ProfessionalsBreadcrumbs from '../ProfessionalsBreadcrumbs';
import { CmsImage, cmsImageToOpenGraph, NavigationTemplate, useGetCmsImageProps } from 'shared';

type NavigationPage = Extract<ContentPage, { __typename: 'professionalsContentPages_navigationPage_Entry' }>;
type NavigationPageChild = NavigationPage['children'][number];
interface NavigationPageContentChild {
    __typename: NavigationPageChild['__typename'];
    title: string;
    uri: string;
    navCtaText: string;
    navIsFeatured: boolean;
    navThumbnail: CmsImage[];
    relatedLinks: Array<{
        label: string;
        linkObject?: {
            target: string;
            uri: string;
            customText: string;
            element?: {
                uri: string;
            };
        };
    }>;
}

const isValidNavigationChild = (child: NavigationPageChild) =>
    child.__typename === 'professionalsContentPages_informationPage_Entry' ||
    child.__typename === 'professionalsContentPages_navigationPage_Entry' ||
    child.__typename === 'professionalsContentPages_berthaFundCollectionPage_Entry' ||
    child.__typename === 'professionalsContentPages_doclabCollectionPage_Entry' ||
    child.__typename === 'professionalsContentPages_docsForSaleCollectionPage_Entry' ||
    child.__typename === 'professionalsContentPages_forumCollectionPage_Entry' ||
    child.__typename === 'professionalsContentPages_projectSpaceCollectionPage_Entry';

const Navigation: FC<{ data: NavigationPage }> = ({ data }) => {
    const getCmsImageProps = useGetCmsImageProps();
    const validNavChildren = useMemo(
        () => data.children.filter(isValidNavigationChild) as NavigationPageContentChild[],
        [data.children]
    );
    const navChildren = useMemo(
        () =>
            validNavChildren.map(navChild => ({
                ...navChild,
                navThumbnail: [getCmsImageProps(navChild.navThumbnail?.[0], navChild.navThumbnail?.[0]?.title)],
            })),
        [getCmsImageProps, validNavChildren]
    );

    return (
        <NavigationTemplate
            pageTitle={data.title || ''}
            pageIntroText={data.introText}
            pageOpenGraphImages={cmsImageToOpenGraph(data.navThumbnail)}
            subPages={navChildren}
            relatedLinkHeading={<FormattedMessage defaultMessage="Bekijk ook" />}
            relatedLinks={data.relatedLinks}
            partners={{
                partnerListings: data.partnerListings,
                partnerListingsText: data.partnerListingsText,
                partnerLogos: data.partnerLogos,
            }}
            breadcrumbs={<ProfessionalsBreadcrumbs />}
        />
    );
};

export default Navigation;
